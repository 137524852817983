import { Container } from "@/components/atoms";
import { PageHeader, ClientsPanel } from "@/components/organisms";
import { ClientsAdd } from "./ClientsAdd";

export const Clients = () => {
  return (
    <Container className="w-full">
      <div className="mb-12 flex flex-1 flex-col">
        <PageHeader skipCrumbs={1} title="Clients" />
        <ClientsPanel />
      </div>
    </Container>
  );
};

Clients.Add = ClientsAdd;
