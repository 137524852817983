import { Link } from "react-router-dom";
import { Divider, Icon, IconButton, LinkButton, SidePanel, Typography } from "@/components/atoms";
import { useOperator } from "@/hooks";
import { useViewVehicleContext } from "../../context";

interface VehicleCategoryModalProps {
  open: boolean;
  onClose: () => void;
}

export const VehicleCategoryModal = ({ open, onClose }: VehicleCategoryModalProps) => {
  const {
    vehicle: { categories, owner },
  } = useViewVehicleContext();
  const operator = useOperator();
  const manageCategoryLink = `/operator/${operator.id}/config/pricing/vehicle-categories`;

  const isAffiliate = owner.uuid !== operator.id;

  return (
    <SidePanel open={open} onClose={onClose} width={430} focused>
      <section className="flex items-center justify-between p-5">
        <div className="flex items-center gap-2">
          <Icon name="Car" size="lg" />
          <Typography variant="title">Vehicle Category</Typography>
        </div>
        <div className="ml-3 flex h-7 items-center">
          <IconButton iconName="ArrowRight" onClick={onClose} className="-mr-2" variant="custom" />
        </div>
      </section>
      <section className="flex h-full flex-1 flex-col p-5">
        <div className="mb-10 rounded bg-neutral-surface-gray p-5">
          <Typography>What is a Vehicle Category?</Typography>
          <br />
          <Typography>
            This defines the vehicle categories considered within the business for transport services.{" "}
            <Link to={manageCategoryLink} className="underline">
              Manage Vehicle Categories
            </Link>
          </Typography>
        </div>
        <div className="flex flex-1 flex-col">
          <Typography variant="action">Vehicle Category</Typography>
          {categories.length === 0 ? (
            <div className="mt-3 flex items-center justify-center rounded border border-neutral-mid-gray p-7">
              <Typography className="text-center text-neutral-black">
                There are currently no vehicle category attached to this vehicle
              </Typography>
            </div>
          ) : (
            <div className="mt-3 flex flex-1 flex-col gap-2">
              {categories.map((category) => (
                <div key={category.uuid} className="flex items-center justify-between rounded bg-neutral-surface-gray px-5 py-2.5">
                  <div className="flex flex-1 items-center gap-3">
                    <Icon name="Car" size={24} />
                    <Typography variant="paragraph">{category.name}</Typography>
                  </div>
                  {/* <div className="flex shrink-0 items-center">
                <Tooltip content="Vehicle Category does not match current settings" placement="left">
                  <Icon name="Danger" size={24} className="text-warning" variant="Bold" />
                </Tooltip>
              </div> */}
                </div>
              ))}
            </div>
          )}
        </div>

        {!isAffiliate && (
          <div className="-mx-5 px-5">
            <Divider className="-mx-5" />
            <LinkButton to="./edit?action=vehicle-category" className="mt-5 w-full" variant="primary" size="lg">
              Update Assigned Vehicle Category
            </LinkButton>
          </div>
        )}
      </section>
    </SidePanel>
  );
};
