import { Button, Modal, Spinner, Typography } from "@/components/atoms";
import { useRemoveApplicationRuleMutation } from "@/redux/apis/driver/driverApi";
import { addToast, clsx } from "@/utils";
import { ApplicationRule } from "../types";

interface ConfirmRemoveApplicationRuleProps extends React.HTMLAttributes<HTMLDivElement> {
  rule: ApplicationRule;
  open: boolean;
  onClose: () => void;
}

export const ConfirmRemoveApplicationRule = ({ className, open, onClose, rule, ...props }: ConfirmRemoveApplicationRuleProps) => {
  const [removeIncident, { isLoading }] = useRemoveApplicationRuleMutation();

  const handleRemove = () => {
    removeIncident(rule.uuid)
      .unwrap()
      .then(() => {
        addToast("success", "Incident removed successfully");
        onClose();
      })
      .catch(() => {
        console.warn("Failed to remove incident");
      });
  };

  return (
    <Modal open={open} onClose={onClose} className="max-w-[514px] !p-5">
      <div className={clsx("relative", className)} {...props}>
        <div className="flex max-w-lg flex-col ">
          <div className="mt-3 flex flex-row items-start">
            <Typography variant="title" className="flex-1">
              Delete Question?
            </Typography>
          </div>
          <div className="mt-3 ">
            <Typography variant="paragraph">
              This will delete your custom registration question. You cannot undo this action, do you wish to continue?
            </Typography>
          </div>
        </div>
        <div className="mt-6 flex justify-end gap-4">
          <Button variant="secondary" onClick={onClose} size="md">
            Cancel
          </Button>
          <Button variant="primary" onClick={handleRemove} className="border-danger bg-danger hover:bg-danger-dark" size="md">
            {isLoading ? <Spinner /> : "Delete"}
          </Button>
        </div>
      </div>
    </Modal>
  );
};
