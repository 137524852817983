import { saveAs } from "file-saver";
import { isEmpty } from "ramda";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Template from "@/assets/templates/upload-bulk-clients-template.xlsx";
import { Button, Panel, Typography, Skeleton, Modal, SearchInput, DropdownMenu, Icon } from "@/components/atoms";
import { Pagination, EmptyState } from "@/components/molecules";
import { usePagination } from "@/hooks";
import { useGetClientsQuery } from "@/redux/apis/client/clientApi";
import { useAppSelector } from "@/redux/hooks";
import { clientsPageSelector, clientsSelector } from "@/redux/slices/client/selectors";
import { Client } from "@/redux/slices/client/types";
import { clsx } from "@/utils";
import { ClientsFilterPanel } from "./ClientsFilterPanel";
import { ClientsImportPanel } from "./ClientsImportPanel";
import { ClientsRemoveAccess } from "./ClientsRemoveAccess";
import { ClientsTable } from "./ClientsTable";
import { ExportClients } from "./actions/ExportClients";
import { useGetClientsParams } from "./hooks/useGetClientsParams";

export const ClientsPanel = () => {
  const navigate = useNavigate();
  const clients = useAppSelector(clientsSelector);
  const { current, count, total, size } = useAppSelector(clientsPageSelector);
  const { handlePageClick, handleSizeChange, clearPagination } = usePagination();
  const { params, filter, setFilter, search, setSearch, sorting, setSorting } = useGetClientsParams();
  const [openFilter, setOpenFilter] = useState(false);
  const [pendingRemoveAccess, setPendingRemoveAccess] = useState<Client>();
  const { isFetching, refetch } = useGetClientsQuery(params, { refetchOnMountOrArgChange: true });
  const [isSearchActive, setIsSearchActive] = useState(false);
  const numActiveFilters = Object.keys(filter).length;
  const [openImport, setOpenImport] = useState(false);

  const handleSearch = (value?: string) => {
    clearPagination();
    setSearch(value);
  };

  const renderActions = () => (
    <DropdownMenu
      button={
        <DropdownMenu.Button as={Button} size="sm" isDropdown className="w-full lg:w-fit">
          Actions
        </DropdownMenu.Button>
      }
      isCustomButton
      position="bottom-start"
    >
      <DropdownMenu.Item onClick={() => navigate("add")} className="w-[200px]">
        <Icon name="Add" className="mr-2.5 text-primary-dark" size="sm" />
        <Typography className="text-primary-dark">Add Client</Typography>
      </DropdownMenu.Item>
      <DropdownMenu.Item onClick={() => setOpenImport(true)} className="w-[200px]">
        <Icon name="DocumentUpload" className="mr-2.5 text-primary-dark" size="sm" />
        <Typography className="text-primary-dark">Upload Client List</Typography>
      </DropdownMenu.Item>
      <DropdownMenu.Item onClick={() => saveAs(Template, "upload-bulk-clients-template")} className="w-[200px]">
        <Icon name="excel" isCustom className="mr-2.5 text-primary-dark" size="sm" />
        <Typography className="text-primary-dark">Download Template</Typography>
      </DropdownMenu.Item>
    </DropdownMenu>
  );

  return (
    <>
      <Panel>
        <Typography variant="h3" className="leading-8">
          Manage Clients
        </Typography>
        {clients.length < 1 && !isSearchActive && isEmpty(filter) && !isFetching ? (
          <EmptyState
            title="Manage Clients"
            description="It looks like you haven't added any clients yet. Begin by adding your first client to get started"
          >
            <Button onClick={() => navigate("add")} startIcon="Add">
              Add Client
            </Button>
          </EmptyState>
        ) : (
          <>
            <div className="mt-3 flex flex-col gap-x-3 gap-y-2 lg:flex-row">
              <div className="flex-1 items-center">
                {!search && !isSearchActive ? (
                  <div className="flex flex-1 items-center justify-end gap-0 xl:gap-2 ">
                    <Button
                      className="max-md:[&>span]:hidden"
                      variant="tertiary"
                      size="sm"
                      startIcon="SearchNormal1"
                      onClick={() => setIsSearchActive(true)}
                    >
                      Search
                    </Button>
                  </div>
                ) : (
                  <div className="flex flex-1 items-center justify-end gap-2">
                    <SearchInput
                      search={search}
                      onSearch={handleSearch}
                      autoFocus
                      placeholder="Search Clients"
                      className={clsx("w-full max-w-sm p-0 sm:w-fit [&>input]:transition-size", isSearchActive && "[&>input]:w-[280px]")}
                      size="sm"
                      onClear={() => setIsSearchActive(false)}
                    />
                  </div>
                )}
              </div>
              <div className="flex flex-col items-center gap-x-3 gap-y-2 lg:flex-row">
                {numActiveFilters > 0 && (
                  <Button
                    size="sm"
                    variant="tertiary"
                    startIcon="close"
                    className="text-primary-dark"
                    isCustomIcon
                    onClick={() => setFilter({})}
                  >
                    Clear Filters
                  </Button>
                )}
                <Button size="sm" variant="secondary" startIcon="Sort" onClick={() => setOpenFilter(true)}>
                  Filter
                </Button>
                <ExportClients />
                {renderActions()}
              </div>
            </div>
            {isFetching ? (
              <Skeleton />
            ) : (
              <ClientsTable
                items={clients}
                sorting={sorting}
                setSorting={setSorting}
                setPendingRemoveAccess={setPendingRemoveAccess}
                isSearchActive={isSearchActive}
                className="mt-4"
              />
            )}
          </>
        )}
      </Panel>
      {!isFetching && (
        <Pagination
          onPageClick={handlePageClick}
          onSizeChange={handleSizeChange}
          currentPage={current}
          pageSize={size}
          totalItems={total}
          pageCount={count}
        />
      )}
      <ClientsFilterPanel open={openFilter} onClose={() => setOpenFilter(false)} filter={filter} setFilter={setFilter} />
      <Modal open={!!pendingRemoveAccess} onClose={() => setPendingRemoveAccess(undefined)} className="max-w-[514px]">
        <ClientsRemoveAccess pendingRemoveAccess={pendingRemoveAccess} setPendingRemoveAccess={setPendingRemoveAccess} />
      </Modal>
      <ClientsImportPanel open={openImport} onClose={() => setOpenImport(false)} refetchClients={() => refetch()} />
    </>
  );
};
