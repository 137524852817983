import { useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Icon, Panel, Typography } from "@/components/atoms";
import { getErrorMessages } from "@/helpers/reduxHelpers";
import { getVehicleTypeLabel } from "@/helpers/vehicleHelpers";
import { useOperator } from "@/hooks";
import { useGetVehicleFormOptionsQuery, vehicleApi } from "@/redux/apis/vehicle/vehicleApi";
import { useUpdateAffiliateVehicleMutation } from "@/redux/apis/vehicleGuest/vehicleGuestApi";
import { Vehicle } from "@/redux/slices/vehicle/types";
import { addToast, capitalize, clsx, getAssetUrl } from "@/utils";
import { getCountryFlagSrc } from "@/utils/getCountryFlagSrc";
import { PageHeader } from "../../PageHeader/PageHeader";
import { ReactivateVehicleModal } from "../ArchivedVehicles/modals/ReactivateVehicleModal";
import { VehicleStatusBadge } from "../MyVehicles/common/VehicleStatusBadge";
import { getInsuranceStatus, getRegistrationStatus } from "../MyVehicles/helpers";
import { ArchiveVehicleModal } from "../MyVehicles/modals/ArchiveVehicleModal";
import { VehicleDetailCard } from "./common/VehicleDetailCard";
import { HistoryLogsDetailCard } from "./components/HistoryLogsDetailCard";
import { RegistrationExpiryDetailCard } from "./components/RegistrationExpiryDetailCard";
import { VehicleAccessDetailCard } from "./components/VehicleAccessDetailCard";
import { VehicleAccreditationDetailCard } from "./components/VehicleAccreditationDetailCard";
import { VehicleCategoryDetailCard } from "./components/VehicleCategoryDetailCard";
import { VehicleInsuranceDetailCard } from "./components/VehicleInsuranceDetailCard";
import { VehicleOperatorNotesDetailCard } from "./components/VehicleOperatorNotesDetailCard";
import { useViewVehicleContext, ViewVehicleProvider } from "./context";
import { VehicleAccessModal } from "./features/VehicleAccess/VehicleAccessModal";
import { VehicleCategoryModal } from "./features/VehicleCategory/VehicleCategoryModal";
import { VehicleDocuments } from "./features/VehicleDocuments/VehicleDocuments";
import { VehicleHistoryLogsModal } from "./features/VehicleHistoryLogs/VehicleHistoryLogsModal";
import { VehicleOperatorNotesModal } from "./features/VehicleOperatorNotes/VehicleOperatorNotesModal";

export const ViewVehiclePanel = () => {
  return (
    <ViewVehicleProvider>
      <PageHeader.Actions />
      <Content />
      <VehicleDocuments />
    </ViewVehicleProvider>
  );
};

const Content = () => {
  const {
    vehicle: {
      metadata: { isArchived },
    },
  } = useViewVehicleContext();

  return (
    <Panel className={clsx("!py-0", isArchived ? "relative overflow-hidden" : "")}>
      {isArchived && (
        <div className="absolute left-0 top-0 flex w-full items-center justify-center bg-danger py-1 text-center text-white">
          <Typography variant="small" className="leading-relaxed">
            Archived Vehicle
          </Typography>
        </div>
      )}
      <div className="flex flex-col lg:flex-row">
        <Sidebar />
        <Details />
        <Modals />
      </div>
    </Panel>
  );
};

const Sidebar = () => {
  const { pathname } = useLocation();
  const { vehicle } = useViewVehicleContext();
  const { avatar, model, registrationNumber, country, state, owner, currentDriver, identifier } = vehicle;

  const isAffiliate = pathname.includes("/vehicles/affiliate-vehicles");

  const renderCurrentDriver = () => {
    if (!currentDriver) return null;

    return (
      <div className="mr-2 flex gap-2">
        <img
          src={getAssetUrl(currentDriver.avatar, {
            width: 48,
            height: 48,
            fit: "contain",
          })}
          className="h-6 w-6 rounded-full"
        />
        <Typography variant="action">{currentDriver.name} (Current Driver)</Typography>
      </div>
    );
  };

  const renderState = () => {
    const flagSrc = getCountryFlagSrc(country.countryCode);

    return (
      <div className="flex gap-2 ">
        {flagSrc && <img src={flagSrc} className="h-6 w-6 rounded-full object-cover" />}
        <Typography variant="action">{state.name}</Typography>
      </div>
    );
  };

  const renderOwner = () => {
    return (
      <>
        <div className="flex items-center gap-2">
          <img
            src={getAssetUrl(owner.avatar, {
              width: 48,
              height: 48,
              fit: "contain",
            })}
            className="-mt-0.5 h-6 w-6 rounded-full border border-neutral-mid-gray object-cover"
          />
          <Typography className="text-neutral-black" variant="action">
            Owned by {owner.name}
          </Typography>
        </div>
        {isAffiliate && (
          <div className="flex items-center gap-2 pl-6">
            <Icon name="Sms" variant="Bold" size="sm" className="text-neutral-dark-gray" />
            <Typography className="whitespace-normal text-neutral-dark-gray" variant="small">
              {owner.email}
            </Typography>
          </div>
        )}
      </>
    );
  };

  const renderRating = () => {
    if (vehicle.rating.jobsCompleted > 5) {
      return (
        <Typography variant="action" className="flex items-center gap-2">
          <Icon name="Star1" className="fill-warning text-warning" />
          {vehicle.rating.rating}
        </Typography>
      );
    }

    return (
      <Typography variant="action" className="flex items-center gap-2 text-neutral-dark-gray">
        {vehicle.rating.jobsCompleted === 0 ? "No reviews yet" : `${vehicle.rating.jobsCompleted} reviews`}
      </Typography>
    );
  };

  return (
    <div className="w-full shrink-0 border-neutral-mid-gray px-5 py-12 lg:w-[280px] lg:border-r xl:w-[360px]">
      <div className="flex flex-1 flex-col gap-7 ">
        <div className="relative self-start">
          <img
            src={getAssetUrl(avatar, {
              width: 200,
              height: 200,
              background: "transparent",
            })}
            alt={model.model}
            className="border-neutral-mid-gray-gray h-[100px] w-[100px] shrink-0 rounded-full border bg-neutral-surface-gray object-contain"
          />
          <div className="absolute bottom-0 right-0 flex items-center justify-center rounded-full border border-neutral-mid-gray bg-neutral-surface-gray p-[7px]">
            <img
              src={getAssetUrl(model.avatar, {
                width: 36,
                height: 36,
                background: "transparent",
                fit: "contain",
              })}
              alt={`${model.make} ${model.model}`}
              className="h-[18px] w-[18px]"
            />
          </div>
        </div>
        <div className="space-y-2">
          <div>
            <Typography variant="h2">
              {`${model.make} ${model.model}`}
              {identifier && <span> ({identifier})</span>}
            </Typography>
            {registrationNumber && (
              <Typography variant="action" className="mt-2 flex items-center gap-2 text-neutral-dark-gray">
                Number Plate: {registrationNumber}
              </Typography>
            )}
            {!vehicle.metadata.isArchived && <Status />}
          </div>
          {renderRating()}
          {renderState()}
          {renderOwner()}
          {renderCurrentDriver()}
          <Actions />
        </div>
      </div>
    </div>
  );
};

const Actions = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id: operatorId } = useOperator();
  const { vehicle } = useViewVehicleContext();
  const [pendingVehicle, setPendingVehicle] = useState<Vehicle | undefined>(undefined);
  const [updateAffiliateVehicle, { isLoading }] = useUpdateAffiliateVehicleMutation();

  const isAffiliate = vehicle.owner.uuid !== operatorId;

  const handleApprove = () => {
    updateAffiliateVehicle({
      ownerId: operatorId,
      vehicleId: vehicle.uuid,
      approval_status: "APPROVED",
    })
      .unwrap()
      .then(() => {
        addToast("success", "Vehicle approved successfully");
        dispatch(vehicleApi.util.invalidateTags(["Vehicle"]));
      })
      .catch((e) => getErrorMessages(e).forEach((message) => addToast("danger", message)));
  };

  const handleDeny = () => {
    updateAffiliateVehicle({
      ownerId: operatorId,
      vehicleId: vehicle.uuid,
      approval_status: "DENIED",
      approval_reason: "Vehicle denied by operator",
    })
      .unwrap()
      .then(() => {
        addToast("success", "Vehicle denied successfully");
        dispatch(vehicleApi.util.invalidateTags(["Vehicle"]));
      })
      .catch((e) => getErrorMessages(e).forEach((message) => addToast("danger", message)));
  };

  const renderArchivedActions = () => (
    <>
      <div className="!mt-5 flex">
        <Button variant="primary" startIcon="Refresh2" onClick={() => setPendingVehicle(vehicle)}>
          Reactivate Vehicle
        </Button>
      </div>
      <ReactivateVehicleModal pendingReactivate={pendingVehicle} setPendingReactivate={setPendingVehicle} />
    </>
  );

  const renderAffiliateActions = () => (
    <div className="!mt-5 flex flex-col gap-3">
      <Button onClick={handleApprove} disabled={isLoading} variant="secondary" className="!border-success text-success">
        Approve Vehicle
      </Button>
      <Button onClick={handleDeny} disabled={isLoading} variant="secondary" className="!border-danger text-danger">
        Deny Vehicle
      </Button>
    </div>
  );

  const renderOperatorActions = () => (
    <>
      <div className="!mt-5 flex flex-col gap-3">
        <Button variant="primary" startIcon="Edit2" onClick={() => navigate(`./edit`)}>
          Edit Vehicle
        </Button>
        <Button variant="secondary" className="!border-danger text-danger" onClick={() => setPendingVehicle(vehicle)}>
          Archive Vehicle
        </Button>
      </div>
      <ArchiveVehicleModal pendingArchive={pendingVehicle} setPendingArchive={setPendingVehicle} />
    </>
  );

  const renderActions = () => {
    if (vehicle.metadata.isArchived) {
      return renderArchivedActions();
    }
    if (isAffiliate) {
      return !vehicle.approval ? renderAffiliateActions() : null;
    }
    return renderOperatorActions();
  };

  return renderActions();
};

const Details = () => {
  const { pathname } = useLocation();
  const { vehicle } = useViewVehicleContext();
  const { data } = useGetVehicleFormOptionsQuery();

  const isAffiliate = pathname.includes("/vehicles/affiliate-vehicles");

  const colors = useMemo(() => {
    if (!data) return [];
    return data.colors;
  }, [data]);

  return (
    <div className="mt-4 grid w-full auto-rows-min grid-cols-1 gap-2.5 sm:px-5 sm:py-5 md:grid-cols-2 2xl:grid-cols-3">
      <VehicleDetailCard
        icon={<Icon name="Calendar" className="text-neutral-black" size="xl" />}
        title="Vehicle Year"
        description={vehicle.year ? vehicle.year.toString() : "-"}
      />
      <VehicleDetailCard
        icon={
          <span
            className="inline-block h-6 w-6 rounded-full border border-neutral-dark-gray"
            style={{
              backgroundColor: colors.find((c) => c.key === vehicle.color)?.hex,
            }}
          />
        }
        title="Vehicle Color"
        description={vehicle.color?.toLowerCase() ?? "-"}
      />
      <VehicleDetailCard
        icon={<Icon name="engine" isCustom className="text-neutral-black" size="xl" />}
        title="Engine Type"
        description={capitalize(vehicle.model.engine.toLowerCase())}
      />
      <VehicleDetailCard
        icon={<Icon name="Profile" className="text-neutral-black" size="xl" />}
        title="Vehicle Capacity"
        description={vehicle.capacity.pax.toString()}
      />
      <VehicleDetailCard
        icon={<Icon name="Briefcase" className="text-neutral-black" size="xl" />}
        title="Number of Bags"
        description={vehicle.capacity.bags.toString()}
      />
      <VehicleDetailCard
        icon={<Icon name="Story" className="text-neutral-black" size="xl" />}
        title="VIN"
        description={vehicle.vin ?? "-"}
      />
      <VehicleCategoryDetailCard />
      <VehicleDetailCard
        icon={<Icon name="Car" className="text-neutral-black" size="xl" />}
        title="Network Eligibility"
        description={capitalize(`${vehicle.model.class.toLowerCase()} ${getVehicleTypeLabel(vehicle.model.type)}`)}
        tooltip={
          <div className="max-w-[295px] font-medium normal-case">
            This helps the system match your vehicle accurately within the platform and with external networks, ensuring proper job
            allocations. <br />
            <br />
            <a
              href="https://rideminder.freshdesk.com/en/support/solutions/articles/51000423941-vehicle-eligibility"
              rel="noreferrer"
              target="_blank"
              className="underline"
            >
              {" "}
              Learn more about Network Eligibility
            </a>
          </div>
        }
      />
      <RegistrationExpiryDetailCard />
      <VehicleInsuranceDetailCard />
      <VehicleAccreditationDetailCard />
      {!vehicle.metadata.isArchived && !isAffiliate && <VehicleAccessDetailCard />}
      <HistoryLogsDetailCard />
      {isAffiliate && <VehicleOperatorNotesDetailCard />}
    </div>
  );
};

const Modals = () => {
  const { vehicle, activeModal, setActiveModal } = useViewVehicleContext();

  return (
    <>
      <VehicleAccessModal
        vehicle={vehicle}
        open={activeModal === "access-manager"}
        onClose={() => {
          setActiveModal(undefined);
        }}
      />
      <VehicleCategoryModal open={activeModal === "vehicle-category"} onClose={() => setActiveModal(undefined)} />
      <VehicleHistoryLogsModal open={activeModal === "history-logs"} onClose={() => setActiveModal(undefined)} />
      <VehicleOperatorNotesModal open={activeModal === "operator-notes"} onClose={() => setActiveModal(undefined)} />
    </>
  );
};

const Status = () => {
  const { vehicle } = useViewVehicleContext();
  const registrationStatus = getRegistrationStatus(vehicle);
  const insuranceStatus = getInsuranceStatus(vehicle);

  const status = useMemo(() => {
    if (registrationStatus === "expired" || insuranceStatus === "expired") {
      return "expired";
    }

    if (registrationStatus === "expiring" || insuranceStatus === "expiring") {
      return "expiring";
    }

    return insuranceStatus === "verified" ? registrationStatus : insuranceStatus;
  }, [registrationStatus, insuranceStatus]);

  switch (status) {
    case "missing-details":
    case "expiring":
    case "expired":
      return (
        <VehicleStatusBadge status={status} vehicle={vehicle} size="sm">
          Attention Required
        </VehicleStatusBadge>
      );

    case "verified":
    default:
      return (
        <VehicleStatusBadge vehicle={vehicle} size="sm">
          Verified
        </VehicleStatusBadge>
      );
  }
};
