import { zodResolver } from "@hookform/resolvers/zod";
import { useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Button, DropdownSelect, ErrorMessage, Panel, PhoneNumberInput, Spinner, TextInput, Typography } from "@/components/atoms";
import { PageHeader } from "@/components/organisms/PageHeader/PageHeader";
import { useOperator } from "@/hooks";
import { useRegisterProviderDriverMutation } from "@/redux/apis/auth/authApi";
import { useGetCountryStatesQuery } from "@/redux/apis/resource/resourceApi";
import { getCountryName } from "@/utils";
import { AddDriverFormData, addDriverFormSchema } from "./form";
import { InvitationSentModal } from "./modals/InvitationSentModal";

export const AddDriverPanel = () => {
  const { name: operatorName } = useOperator();
  return (
    <div className="mb-12 flex flex-1 flex-col">
      <PageHeader.Actions />
      <Panel className="mx-auto flex w-full flex-col p-[30px]">
        <Typography variant="h3" className="mb-1.5">
          Sign up a New Driver
        </Typography>
        <Typography variant="paragraph" className="text-neutral-dark-gray">
          Add a new {operatorName} driver profile
        </Typography>

        <Typography variant="title" className="mt-7">
          Personal Details
        </Typography>
        <Form />
      </Panel>
    </div>
  );
};

const Form = () => {
  const navigate = useNavigate();
  const { bookableCountryList, country: defaultCountry, id: providerId } = useOperator();
  const [country, setCountry] = useState(defaultCountry);
  const { data, isFetching } = useGetCountryStatesQuery(country);
  const [registerDriver] = useRegisterProviderDriverMutation();
  const [pendingUserEmail, setPendingUserEmail] = useState("");

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty, isSubmitting },
    control,
  } = useForm<AddDriverFormData>({
    resolver: zodResolver(addDriverFormSchema),
  });

  const countryOptions = useMemo(() => {
    return bookableCountryList
      .map((countryCode) => ({
        name: getCountryName(countryCode) || "",
        value: countryCode,
      }))
      .filter((country) => !!country.name);
  }, [bookableCountryList]);

  const stateOptions = useMemo(() => {
    if (!country || !data || data.data.states.length < 1) return [];

    return data.data.states.map((state) => ({
      name: state.name,
      value: state.id,
    }));
  }, [country, data]);

  const handleCancel = () => {
    navigate("../");
  };

  const onSubmit = async (data: AddDriverFormData) => {
    return registerDriver({
      providerId,
      registered_device: "Web Browser",
      email: data.email,
      personal: {
        first_name: data.firstName,
        last_name: data.lastName,
        mobile: data.contactNumber,
      },
      licence: {
        state_uuid: data.stateRegistered,
      },
      require_password_change: true,
      send_login_instructions: true,
    }).then(() => {
      setPendingUserEmail(data.email);
    });
  };

  const handleModalClose = () => {
    setPendingUserEmail("");
    navigate("../");
  };

  return (
    <>
      <InvitationSentModal email={pendingUserEmail} open={!!pendingUserEmail} onClose={handleModalClose} />
      <form className="mt-3 grid grid-cols-3 gap-5" onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col gap-y-1">
          <Typography variant="paragraph">First Name</Typography>
          <TextInput className="w-full" hasError={!!errors.firstName} placeholder="Enter Driver’s First Name" {...register("firstName")} />
          <ErrorMessage errors={errors} name="firstName" />
        </div>
        <div className="flex flex-col gap-y-1">
          <Typography variant="paragraph">Last Name</Typography>
          <TextInput className="w-full" hasError={!!errors.lastName} placeholder="Enter Driver’s Last Name" {...register("lastName")} />
          <ErrorMessage errors={errors} name="lastName" />
        </div>
        <div className="flex flex-col gap-y-1">
          <Typography variant="paragraph">Email Address (Username)</Typography>
          <TextInput className="w-full" hasError={!!errors.email} placeholder="Enter Driver’s Last Name" {...register("email")} />
          <ErrorMessage errors={errors} name="email" />
        </div>
        <div className="flex flex-col gap-y-1">
          <Typography variant="paragraph">Country</Typography>
          <DropdownSelect
            className="w-full"
            options={countryOptions}
            placeholder="Select Country"
            value={country}
            onChange={(value: string) => setCountry(value)}
            disabled={isFetching}
          />
        </div>
        <div className="flex flex-col gap-y-1">
          <Typography variant="paragraph">State</Typography>
          <Controller
            name="stateRegistered"
            control={control}
            render={({ field }) => (
              <DropdownSelect
                className="w-full"
                options={stateOptions}
                placeholder="Select State"
                value={field.value?.toString()}
                onChange={(value) => field.onChange(value)}
                hasError={!!errors.stateRegistered}
                disabled={isFetching || stateOptions.length < 1}
              />
            )}
          />
          <ErrorMessage errors={errors} name="stateRegistered" />
        </div>
        <div className="flex flex-col gap-y-1">
          <Typography variant="paragraph">Contact Number</Typography>
          <PhoneNumberInput control={control} name="contactNumber" hasError={!!errors.contactNumber} />
          <ErrorMessage errors={errors} name="contactNumber" />
        </div>
        <div className="col-span-3 mt-10 flex justify-end gap-4">
          <Button onClick={handleCancel} variant="secondary" size="lg" disabled={isSubmitting}>
            Cancel
          </Button>
          <Button variant="primary" size="lg" type="submit" disabled={isSubmitting || !isDirty}>
            {isSubmitting ? <Spinner /> : "Send Login Instructions"}
          </Button>
        </div>
      </form>
    </>
  );
};
